<template>
  <div class="submenu">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
    }
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.submenu {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
</style>
